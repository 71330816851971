/**
 * 存储tokens
 * @param {string} accessToken
 */
/* export function saveToken(accessToken) {
  localStorage.setItem('token', accessToken)
} */

/**
 * 获得某个token
 * @param {string} tokenKey
 */
/* export function getToken(tokenKey) {
  return localStorage.getItem(tokenKey)
} */

/**
 * 移除token
 */
/* export function removeToken() {
  localStorage.removeItem('token')
} */

/**
 * token key 常量
 * @type {string}
 */
const TokenKey = 'ABBE_TOKEN_KEY'

/**
 * token 获取的时间 key 常量
 * @type {string}
 */
const TokenSaveTimeKey = 'ABBE_TOKEN_TIME_KEY'

/**
 * token 有效期，为服务器过期时间的一半
 * @type {string}
 */
const TokenValidityDuration = 12 * 60 * 60 * 1000

const token = {
  /**
   * 静态token数据，为了减少localStorage存储交互
   */
  token: '',

  /**
   * 静态token存储的时间，用于判断token过半
   */
  tokenSaveTime: '',

  /**
   * 判定token是否过半，过半重新进入的时候，直接需要重新登录，未过半直接进入系统
   * @returns {boolean} false 过期； true 未过期
   */
  checkToken() {
    let saveTime = localStorage.getItem(TokenSaveTimeKey) || 0
    let nowTime = new Date().getTime()
    if (nowTime - parseInt(saveTime) > TokenValidityDuration) {
      return false
    } else {
      return true
    }
  },

  /**
   * 获取token
   * @returns {string}
   */
  getToken() {
    if (this.token) {
      return this.token
    }
    let t = localStorage.getItem(TokenKey)
    this.token = t && t !== 'undefined' ? t : ''
    return this.token
  },

  /**
   * 存储token
   * @param token
   */
  saveToken(token) {
    this.token = token

    // config.headers["X-ABEI-TOKEN"] = token

    // document.cookie='name=赵子龙';
    // document.cookie="X-ABEI-TOKEN"+"="+token+";";

    localStorage.setItem(TokenKey, this.token)
    let nowTime = new Date().getTime()
    localStorage.setItem(TokenSaveTimeKey, nowTime)
  },

  /**
   * 移除存储的token，一般用于退出登录
   */
  removeToken() {
    this.token = ''
    localStorage.removeItem(TokenKey)
    localStorage.removeItem(TokenSaveTimeKey)
  }
}

export default token
