import axios from "axios";
// import Qs from "qs";
import config from "../config/config";
import token from './token'
import { ElMessage } from 'element-plus';
import router from "../router";

let Axios = axios.create({
  baseURL: config.baseURL,
  timeout: 20000,
  responseType: "json",
  withCredentials: false,
  headers: {
    "Content-Type": "application/json"
  }
});

Axios.interceptors.request.use(
  config => {
    if (config.method == "post") {
      let postData = config.data;
      let nArgs = {}

      const _token = token.getToken()
      if (_token && _token !== 'undefined') {
        nArgs.token = _token
      }
      for (let key in postData) {
        nArgs[key] = postData[key]
      }

      let d = JSON.stringify(nArgs, { arrayFormat: "repeat" });
      config.data = d;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 返回状态判断(添加响应拦截器)
Axios.interceptors.response.use(
  res => {
    let { code, data } = res.data
    // token 异常，直接登出
    if (code == 1000) {
      ElMessage({
        message: '登录已过期，请重新登录！',
        type: 'error'
      });

      router.replace('/login')
      return ;
    }
    return res.data
  },
  error => {
    ElMessage({
      message: '请求数据失败，请稍后尝试！',
      type: 'error'
    });
    return Promise.reject(error);
  }
);

export default Axios;
